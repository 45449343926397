import cx from 'classnames';
import { enableAllPlugins } from 'immer';
import { atom, useAtom } from 'jotai';
import { useImmerAtom } from 'jotai/immer';
import uniqueId from 'lodash/uniqueId';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo
  } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { alea } from 'seedrandom';
import { Canvas, DrawFunction } from './canvas';
import {
  GenerationOptions,
  render,
  RenderOptions,
} from "../../renderer/renderer";
import {
  HouseGenerator,
  Rectangle,
  RelativeDirection,
} from "../../generator/HouseGenerator";

enableAllPlugins();

const titleImage = require("./title.png");

const roomsAtom = atom(3);
const squaresAtom = atom(3);
const seedAtom = atom(182176);
const gridWidthAtom = atom(24);
const gridHeightAtom = atom(16);
const initialRectangleAtom = atom<Rectangle>({
  start: { x: 5, y: 5 },
  end: { x: 10, y: 10 },
});
const maxOutwardAtom = atom(3);
const weightsAtom = atom(
  new Map<RelativeDirection, number>([
    ["keep going", 9],
    ["forward", 3],
    ["outward", 3],
    ["inward", 3],
    ["diagonal outward", 1],
    ["diagonal inward", 1],
  ])
);

function usePrng() {
  return useOptions().randomGenerator;
}

function useOptions() {
  const [rooms] = useAtom(roomsAtom);
  const [squares] = useAtom(squaresAtom);
  const [seed] = useAtom(seedAtom);
  const [gridWidth] = useAtom(gridWidthAtom);
  const [gridHeight] = useAtom(gridHeightAtom);
  const [initialRectangle] = useAtom(initialRectangleAtom);
  const [maxOutward] = useAtom(maxOutwardAtom);
  const [weights] = useAtom(weightsAtom);

  const data = useMemo<GenerationOptions>(
    () => ({
      numberOfRooms: rooms,
      squares: squares,
      randomGenerator: alea(String(seed)),
      grid: {
        width: gridWidth,
        height: gridHeight,
      },
      canvas: {
        width: 30 * 35,
        height: 20 * 35,
      },
      initialRectangle: initialRectangle,
      maxOutward: maxOutward,
      weights: weights,
    }),
    [
      rooms,
      squares,
      seed,
      gridHeight,
      gridWidth,
      initialRectangle,
      maxOutward,
      weights,
    ]
  );
  return data;
}

function useUpdateUrlHash() {
  const [seed, setSeed] = useAtom(seedAtom);
  const [, setShowAdminSettings] = useAtom(showAdminSettingsAtom);
  const router = useRouter();
  const { asPath } = router;

  const params = asPath.slice(2, Infinity).split("&");
  const data: Record<string, string> = {};
  params.forEach((keyAndValue) => {
    const [key, value] = keyAndValue.split("=");
    if (key?.length > 0) {
      data[key] = value;
    }
  });

  // First read
  useEffect(() => {
    try {
      if (data["seed"] !== undefined) {
        setSeed(Number.parseInt(data["seed"]));
      }

      if (data["extra"] === "true") {
        setShowAdminSettings(true);
      }
    } catch {}
  }, [asPath]);

  useEffect(() => {
    const data2 = { ...data };
    data2["seed"] = String(seed);

    const flattenedParams = Object.entries(data2)
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join("&");

    window.location.hash = `#${flattenedParams}`;
  }, [seed]);
}
export function Page() {
  const options = useOptions();
  useUpdateUrlHash();
  const [, setShowAdminSettings] = useAtom(showAdminSettingsAtom);

  return (
    <>
      <style jsx global>{`
        html,
        body,
        body > div:first-child,
        div#__next,
        div#__next > div {
          height: 100%;
        }
        #__next {
          min-height: 100%;
        }

        body {
          background-color: #cef2f5;
          background-image: url("data:image/svg+xml,%3Csvg width='840' height='480' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%2309466d' fill-opacity='0.13' fill-rule='evenodd'/%3E%3C/svg%3E");
        }
      `}</style>
      <div className="flex justify-center h-full">
        <div className="max-w-4xl px-4 pb-32 space-y-8">
          <div className="flex justify-center w-full pt-4 md:pt-8">
            <Image
              src={titleImage}
              alt="Sims Shell Generator"
              useMap="#adminclick"
            />
            <map name="adminclick" id="adminclick">
              <area
                className="cursor-pointer"
                shape="circle"
                coords="175,7,10"
                onClick={() => {
                  setShowAdminSettings(true);
                }}
                alt="Secret extra settings"
              />
            </map>
          </div>
          <section>
            <div
              className={cx(
                "flex",
                "flex-col-reverse gap-y-8",
                "md:flex-row md:gap-x-8"
              )}
            >
              <div className="flex flex-col items-center justify-center gap-y-8">
                <Randomize />
                <div className="flex flex-col gap-y-4">
                  <SeedInput />
                </div>
              </div>
              <div>
                <ErrorBoundary
                  fallback={
                    <section
                      className="flex items-center p-8 text-2xl bg-white shadow-xl rounded-xl"
                      style={{ height: "417px" }}
                    >
                      <div>
                        <p>Something went wrong!</p>
                        <p>
                          Please try different settings or refresh the page.
                        </p>
                      </div>
                    </section>
                  }
                  resetKeys={[options]}
                >
                  <RenderArea />
                </ErrorBoundary>
              </div>
            </div>
          </section>
          <section>
            <UserSettings />
          </section>
          <AdminSettings />
          <section className="p-8 bg-white shadow-xl rounded-xl">
            <div className="space-y-4 max-w-prose">
              <p>
                The Shellcaster is a Sims shell generator for The Sims shell
                challenges!
              </p>
              <section>
                <MiniHeading>What is a Sims shell challenge?</MiniHeading>
                <p>
                  Popularised by YouTubers and streamers like{" "}
                  <a
                    target="_blank"
                    className={linkClasses}
                    href="https://www.youtube.com/lilsimsie"
                  >
                    lilsimsie
                  </a>
                  , shell challenges are a kind of Sims build challenge that
                  provide a fun constraint to inspire a creative Sims build.
                  Just use the wall tool to copy the shell into build mode, and
                  then turn it into a cosy cottage, or a modern mansion, or a
                  ridiculous restaurant… whatever you can imagine!
                </p>
                <p>
                  As lilsimsie would say, the only rule is: “Do not touch my
                  walls.” You can move and rotate the shell, add interior walls
                  to create rooms, change the wall height, add foundation and
                  platforms, and attach half walls/fences/porches to the outside
                  of the shell. But the original shell should stay the same!
                </p>
              </section>
              <section>
                <MiniHeading>
                  What if the generator makes something too boring/weird?
                </MiniHeading>
                <p>
                  Reroll! We’re still tweaking the algorithm, so if you have
                  feedback or suggestions get in touch:
                  <a
                    target="_blank"
                    className={linkClasses}
                    href="https://twitter.com/theshellcaster"
                  >
                    {" "}
                    @TheShellCaster{" "}
                  </a>
                </p>
              </section>
              <section>
                <MiniHeading>How can I generate multiple storeys?</MiniHeading>
                <p>
                  A future update will add the ability to generate multiple
                  storeys. For now, we suggest generating an additional shell
                  the same size or smaller, and trying to combine the two in an
                  interesting way. Jerica ended up building a pretty good beach
                  house this way!
                </p>
              </section>
              <section>
                <MiniHeading>How can I share my builds with you?</MiniHeading>
                <p>
                  Upload your builds to the Sims gallery and use the hashtag{" "}
                  <strong>#Shellcaster</strong> in the description. Share
                  screenshots with us on Twitter:{" "}
                  <a
                    target="_blank"
                    className={linkClasses}
                    href="https://twitter.com/theshellcaster"
                  >
                    {" "}
                    @TheShellCaster{" "}
                  </a>
                </p>
              </section>
              <section>
                <MiniHeading>
                  How can I use the generator to set my own shell challenges?
                </MiniHeading>
                <p>
                  If you share the seed number and size setting with your
                  followers, they will be able to access the exact same shell as
                  you. See who can turn it into the most interesting Sims build!
                </p>
              </section>
              <section>
                <ul>
                  <li>
                    <ListTitle>Idea:</ListTitle> Jordan Erica Webber{" "}
                    <a
                      target="_blank"
                      href="https://twitter.com/jericawebber"
                      className={linkClasses}
                    >
                      [twitter]
                    </a>
                    <a
                      target="_blank"
                      href="https://www.twitch.tv/JericaWebber"
                      className={linkClasses}
                    >
                      [twitch]
                    </a>
                  </li>
                  <li>
                    <ListTitle>Algorithm:</ListTitle> Jessica Baker{" "}
                    <a
                      target="_blank"
                      href="https://twitter.com/JessBoneBreaker"
                      className={linkClasses}
                    >
                      [twitter]
                    </a>
                  </li>
                  <li>
                    <ListTitle>Visualization:</ListTitle> Topher Winward{" "}
                    <a
                      target="_blank"
                      href="https://twitter.com/Winwardo"
                      className={linkClasses}
                    >
                      [twitter]
                    </a>
                  </li>
                </ul>
              </section>
              <section>
                <p>
                  Feedback/suggestions? Get in touch:{" "}
                  <a
                    target="_blank"
                    className={linkClasses}
                    href="https://twitter.com/theshellcaster"
                  >
                    {" "}
                    @TheShellCaster{" "}
                  </a>
                </p>
              </section>
            </div>
          </section>
          <div className="h-16" />
        </div>
      </div>
    </>
  );
}

function MiniHeading({ children }: { children: ReactNode }) {
  return <h2 className="font-bold">{children}</h2>;
}

function useRenderOptions() {
  const [showAdminSettings] = useAtom(showAdminSettingsAtom);
  return useMemo<RenderOptions>(() => {
    return {
      displayGridNumbers: showAdminSettings,
    };
  }, [showAdminSettings]);
}

function RenderArea() {
  const generationOptions = useOptions();
  const houseData = useMemo(
    () => new HouseGenerator(generationOptions).generate(),
    [generationOptions]
  );
  const renderOptions = useRenderOptions();

  const draw = useCallback<DrawFunction>(
    (ctx, frameCount: number, options: GenerationOptions) => {
      render(ctx, frameCount, options, houseData, renderOptions);
    },
    [houseData, renderOptions]
  );

  return <Canvas drawFunction={draw} options={generationOptions} />;
}

const linkClasses = "text-blue-500 underline hover:text-blue-700";
function ListTitle({ children }: { children: React.ReactNode }) {
  return <span className="text-sm font-semibold ">{children}</span>;
}

function Randomize() {
  const [seed, setSeed] = useAtom(seedAtom);
  return (
    <button
      onClick={() => {
        setSeed(Math.round(Math.random() * 1000000));
      }}
      className={cx(
        "px-6 py-4 text-3xl italic text-green-50 rounded shadow-lg bg-gradient-to-b  font-display-1",
        "from-green-400 to-green-600",
        "active:from-green-500 active:to-green-700"
      )}
    >
      Randomize!
    </button>
  );
}

export function TextInput({
  textInputProps,
  value,
  onChange,
  noShadow = false,
}: {
  textInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  value: string;
  onChange: (value: string) => void;
  noShadow?: boolean;
}) {
  return (
    <input
      className={cx(
        "px-2 py-1 border rounded w-full",
        !noShadow && "shadow-inner"
      )}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      {...textInputProps}
    />
  );
}
function useUniqueId(prefix?: string) {
  return useMemo(() => uniqueId(prefix), []);
}
function useUniqueFormId() {
  return useUniqueId("form-");
}

export function RangeInput({
  value,
  onChange,
  label,
  min,
  max,
}: {
  value: number;
  onChange: (value: number) => void;
  label: React.ReactNode;
  min?: number;
  max?: number;
}) {
  const id = useUniqueFormId();

  return (
    <div>
      <label className="text-sm font-semibold" htmlFor={id}>
        {label}
      </label>
      <TextInput
        value={String(value)}
        onChange={(value) => {
          try {
            const asNumber = Number.parseInt(value);
            onChange(asNumber);
          } catch {}
        }}
        textInputProps={{
          id: id,
          type: "range",
          min: min,
          max: max,
        }}
        noShadow
      />
    </div>
  );
}

export function NumberInput({
  value,
  onChange,
  label,
  min,
  max,
}: {
  value: number;
  onChange: (value: number) => void;
  label: React.ReactNode;
  min?: number;
  max?: number;
}) {
  const id = useUniqueFormId();

  return (
    <div className="">
      <label className="block text-sm font-semibold" htmlFor={id}>
        {label}
      </label>
      <TextInput
        value={String(value)}
        onChange={(value) => {
          try {
            const asNumber = Number.parseInt(value);
            onChange(asNumber);
          } catch {}
        }}
        textInputProps={{ id: id, type: "number", min: min, max: max }}
      />
    </div>
  );
}

function RoomsInput() {
  const [rooms, setRooms] = useAtom(roomsAtom);
  return (
    <>
      <NumberInput value={rooms} onChange={setRooms} label={<>Rooms</>} />
    </>
  );
}

function SquaresInput() {
  const [squares, setSquares] = useAtom(squaresAtom);
  return (
    <NumberInput value={squares} onChange={setSquares} label={<>Squares</>} />
  );
}

function SeedInput() {
  const [seed, setSeed] = useAtom(seedAtom);
  const prng = usePrng();

  return (
    <NumberInput value={seed} onChange={setSeed} label={<>Random seed</>} />
  );
}

const minGridSize = 4;
const maxGridSize = 64;

function GridInput() {
  const [gridWidth, setGridWidth] = useAtom(gridWidthAtom);
  const [gridHeight, setGridHeight] = useAtom(gridHeightAtom);
  return (
    <div>
      <span className="text-xl text-gray-400 uppercase">Grid</span>
      <div>
        <NumberInput
          value={gridWidth}
          onChange={(value) => {
            setGridWidth(clamp(minGridSize, maxGridSize, value));
          }}
          label={<>Grid width</>}
          min={minGridSize}
          max={maxGridSize}
        />
        <RangeInput
          value={gridWidth}
          onChange={(value) => {
            setGridWidth(clamp(minGridSize, maxGridSize, value));
          }}
          label={<></>}
          min={minGridSize}
          max={maxGridSize}
        />
      </div>
      <div>
        <NumberInput
          value={gridHeight}
          onChange={(value) => {
            setGridHeight(clamp(minGridSize, maxGridSize, value));
          }}
          label={<>Grid height</>}
          min={minGridSize}
          max={maxGridSize}
        />
        <RangeInput
          value={gridHeight}
          onChange={(value) => {
            setGridHeight(clamp(minGridSize, maxGridSize, value));
          }}
          label={<></>}
          min={minGridSize}
          max={maxGridSize}
        />
      </div>
    </div>
  );
}

function clamp(min: number, max: number, value: number): number {
  return Math.min(Math.max(min, value), value);
}

const showAdminSettingsAtom = atom(false);
function AdminSettings() {
  const [showAdminSettings] = useAtom(showAdminSettingsAtom);

  if (!showAdminSettings) {
    return null;
  }

  return (
    <section className="flex flex-col justify-around p-8 bg-white shadow-lg md:gap-x-12 gap-y-8 md:flex-row rounded-xl">
      <InitialRectangleInput />
      <MaxOutwardInput />
      <Weights />
      <GridInput />
    </section>
  );
}

function UserSettings() {
  const [initialRectangle, setInitialRectangle] = useAtom(initialRectangleAtom);
  const [gridWidth, setGridWidth] = useAtom(gridWidthAtom);
  const [gridHeight, setGridHeight] = useAtom(gridHeightAtom);
  const [maxOutwards, setMaxOutwards] = useAtom(maxOutwardAtom);
  const [weights, setWeights] = useImmerAtom(weightsAtom);

  return (
    <div className="flex justify-center gap-x-10">
      <button
        onClick={() => {
          setInitialRectangle({ start: { x: 5, y: 5 }, end: { x: 10, y: 10 } });
          setGridWidth(22);
          setGridHeight(15);
          setMaxOutwards(3);
          setWeights((weights) => {
            weights.set("forward", 3);
            weights.set("keep going", 9);
          });
        }}
        className={cx(
          "px-6 py-2 italic text-3xl text-blue-50 rounded border-4 border-blue-50 shadow-lg bg-[#2b2b56] font-display-1",
          "active:bg-[#7373a9]"
        )}
      >
        Small
      </button>
      <button
        onClick={() => {
          setInitialRectangle({ start: { x: 9, y: 9 }, end: { x: 19, y: 19 } });
          setGridWidth(42);
          setGridHeight(28);
          setMaxOutwards(7);
          setWeights((weights) => {
            weights.set("forward", 7);
            weights.set("keep going", 21);
          });
        }}
        className={cx(
          "px-6 py-2 italic text-3xl text-blue-50 rounded border-4 border-blue-50 shadow-lg bg-[#2b2b56] font-display-1",
          "active:bg-[#7373a9]"
        )}
      >
        Large
      </button>
    </div>
  );
}

function InitialRectangleInput() {
  const [initialRectangle, setInitialRectangle] =
    useImmerAtom(initialRectangleAtom);
  const [gridWidth] = useAtom(gridWidthAtom);
  const [gridHeight] = useAtom(gridHeightAtom);

  return (
    <div>
      <span className="text-xl text-gray-400 uppercase">Initial rectangle</span>
      <div className="grid grid-cols-2 grid-rows-2 gap-4">
        <NumberInput
          label={<>Start X</>}
          value={initialRectangle.start.x}
          onChange={(value) => {
            setInitialRectangle((rect) => {
              rect.start.x = value;
            });
          }}
          min={1}
          max={initialRectangle.end.x - 1}
        />
        <NumberInput
          label={<>End X</>}
          value={initialRectangle.end.x}
          onChange={(value) => {
            setInitialRectangle((rect) => {
              rect.end.x = value;
            });
          }}
          min={initialRectangle.start.x + 1}
          max={gridWidth}
        />
        <NumberInput
          label={<>Start Y</>}
          value={initialRectangle.start.y}
          onChange={(value) => {
            setInitialRectangle((rect) => {
              rect.start.y = value;
            });
          }}
          min={1}
          max={initialRectangle.end.y - 1}
        />
        <NumberInput
          label={<>End Y</>}
          value={initialRectangle.end.y}
          onChange={(value) => {
            setInitialRectangle((rect) => {
              rect.end.y = value;
            });
          }}
          min={initialRectangle.start.y + 1}
          max={gridHeight - 1}
        />
      </div>
    </div>
  );
}

function MaxOutwardInput() {
  const [maxOutward, setMaxOutward] = useAtom(maxOutwardAtom);

  return (
    <div>
      <span className="text-xl text-gray-400 uppercase">Misc</span>
      <div>
        <NumberInput
          label={<>Max outwards steps</>}
          value={maxOutward}
          onChange={(value) => {
            setMaxOutward(value);
          }}
          min={1}
          max={10}
        />
      </div>
    </div>
  );
}

function Weights() {
  const [weights, setWeights] = useImmerAtom(weightsAtom);

  return (
    <div>
      <span className="text-xl text-gray-400 uppercase">Weights</span>
      <div className="grid w-full grid-cols-2 md:w-64 gap-x-4 md:gap-x-8">
        <NumberInput
          label={<>Keep going</>}
          value={weights.get("keep going")!}
          onChange={(value) => {
            setWeights((weights) => {
              weights.set("keep going", value);
            });
          }}
          min={0}
          max={Infinity}
        />
        <NumberInput
          label={<>Forward</>}
          value={weights.get("forward")!}
          onChange={(value) => {
            setWeights((weights) => {
              weights.set("forward", value);
            });
          }}
          min={0}
          max={Infinity}
        />
        <NumberInput
          label={<>Outward</>}
          value={weights.get("outward")!}
          onChange={(value) => {
            setWeights((weights) => {
              weights.set("outward", value);
            });
          }}
          min={0}
          max={Infinity}
        />
        <NumberInput
          label={<>Inward</>}
          value={weights.get("inward")!}
          onChange={(value) => {
            setWeights((weights) => {
              weights.set("inward", value);
            });
          }}
          min={0}
          max={Infinity}
        />
        <NumberInput
          label={<>Diagonal Outward</>}
          value={weights.get("diagonal outward")!}
          onChange={(value) => {
            setWeights((weights) => {
              weights.set("diagonal outward", value);
            });
          }}
          min={0}
          max={Infinity}
        />
        <NumberInput
          label={<>Diagonal Inward</>}
          value={weights.get("diagonal inward")!}
          onChange={(value) => {
            setWeights((weights) => {
              weights.set("diagonal inward", value);
            });
          }}
          min={0}
          max={Infinity}
        />
      </div>
    </div>
  );
}
