import { alea } from 'seedrandom';
import { DrawFunction } from '../pagesLib/index/canvas';
import {
  HouseData,
  HouseGenerator,
  Rectangle,
  RelativeDirection,
} from "../generator/HouseGenerator";

export type GenerationOptions = {
  numberOfRooms: number;
  squares: number;
  randomGenerator: ReturnType<typeof alea>;
  grid: {
    width: number;
    height: number;
  };
  canvas: {
    width: number;
    height: number;
  };
  initialRectangle: Rectangle;
  maxOutward: number;
  weights: Map<RelativeDirection, number>;
};

export type RenderOptions = {
  displayGridNumbers: boolean;
};

export const render = (
  ctx: CanvasRenderingContext2D,
  frameCount: number,
  generationOptions: GenerationOptions,
  houseData: HouseData,
  renderOptions: RenderOptions
) => {
  new Renderer(ctx, frameCount, generationOptions, renderOptions).render(
    houseData
  );
};

class Renderer {
  constructor(
    private ctx: CanvasRenderingContext2D,
    private frameCount: number,
    private generationOptions: GenerationOptions,
    private renderOptions: RenderOptions
  ) {}

  public render(houseData: HouseData) {
    this.clearScreen();
    this.drawGrid();
    this.drawHouseData(houseData);
  }

  private clearScreen() {
    this.ctx.fillStyle = "white";
    this.ctx.fillRect(
      0,
      0,
      this.generationOptions.canvas.width,
      this.generationOptions.canvas.height
    );
  }

  private getGap() {
    const { generationOptions: options } = this;
    const canvasWidth = options.canvas.width;
    const canvasHeight = options.canvas.height;

    let columnGap = canvasWidth / options.grid.width;
    let rowGap = canvasHeight / options.grid.height;

    if (rowGap > columnGap) {
      rowGap = columnGap;
    } else {
      columnGap = rowGap;
    }
    const gap = rowGap;

    return gap;
  }

  private drawGrid() {
    const { ctx, generationOptions, renderOptions } = this;

    ctx.strokeStyle = "grey";

    const gap = this.getGap();

    for (let column = 0; column <= generationOptions.grid.width; column++) {
      ctx.beginPath();

      ctx.lineWidth = 1;

      const x = column * gap;
      const maxY = gap * generationOptions.grid.height;

      ctx.moveTo(x, 0);
      ctx.lineTo(x, maxY);
      ctx.stroke();

      if (renderOptions.displayGridNumbers) {
        ctx.font = "24px monospace";
        this.ctx.fillStyle = "black";
        ctx.fillText(`${column}`, x, 22);
      }
    }

    for (let row = 0; row <= generationOptions.grid.height; row++) {
      ctx.beginPath();
      ctx.lineWidth = 1;

      const y = row * gap;
      const maxX = gap * generationOptions.grid.width;

      ctx.moveTo(0, y);
      ctx.lineTo(maxX, y);
      ctx.stroke();

      if (renderOptions.displayGridNumbers) {
        ctx.font = "24px monospace";
        this.ctx.fillStyle = "black";
        ctx.fillText(`${row}`, 0, y + 22);
      }
    }
  }

  private drawHouseData(houseData: HouseData) {
    const { ctx } = this;
    ctx.strokeStyle = "black";
    const gap = this.getGap();

    houseData.walls.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (cell.top) {
          ctx.beginPath();
          ctx.lineWidth = 5;

          ctx.moveTo(x * gap, y * gap);
          ctx.lineTo((x + 1) * gap, y * gap);
          ctx.stroke();
        }

        if (cell.left) {
          ctx.beginPath();
          ctx.lineWidth = 5;

          ctx.moveTo(x * gap, y * gap);
          ctx.lineTo(x * gap, (y + 1) * gap);
          ctx.stroke();
        }

        if (cell.topLeftToBottomRight) {
          ctx.beginPath();
          ctx.lineWidth = 5;

          ctx.moveTo(x * gap, y * gap);
          ctx.lineTo((x + 1) * gap, (y + 1) * gap);
          ctx.stroke();
        }

        if (cell.topRightToBottomLeft) {
          ctx.beginPath();
          ctx.lineWidth = 5;

          ctx.moveTo((x + 1) * gap, y * gap);
          ctx.lineTo(x * gap, (y + 1) * gap);
          ctx.stroke();
        }
      });
    });
  }
}
