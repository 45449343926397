import { apiResolver } from 'next/dist/server/api-utils';
import { GenerationOptions } from '../renderer/renderer';

export type HouseData = {
  walls: Array<Array<HouseGridCell>>;
};

export type HouseGridCell = {
  top: boolean;
  // bottom: boolean;
  left: boolean;
  // right: boolean;
  topLeftToBottomRight: boolean;
  topRightToBottomLeft: boolean;
};

type Coordinate = {
  x: number;
  y: number;
};

export type Rectangle = {
  start: Coordinate;
  end: Coordinate;
};

function makeEmptyCell(): HouseGridCell {
  return {
    top: false,
    left: false,
    topLeftToBottomRight: false,
    topRightToBottomLeft: false,
  };
}

export type RelativeDirection =
  | "forward"
  | "inward"
  | "outward"
  | "diagonal inward"
  | "diagonal outward"
  | "keep going";

export type AbsoluteDirection =
  | "left"
  | "down"
  | "right"
  | "up"
  | "diagonal down left"
  | "diagonal down right"
  | "diagonal up left"
  | "diagonal up right";

type SideCategory = "left" | "top" | "bottom" | "right";

type SideCell = {
  position: Coordinate;
  direction: AbsoluteDirection;
};

type Side = {
  mappings: Map<RelativeDirection, AbsoluteDirection>;
  direction: SideCategory;
  cells: Array<SideCell>;
  endedOnDiagonalBumpy: boolean;
  endedOnStraightBumpy: boolean;
};

function SetupAbsoluteDirections(side: Side) {
  if (side.direction === "left") {
    side.mappings.set("forward", "down");
    side.mappings.set("inward", "right");
    side.mappings.set("outward", "left");
    side.mappings.set("diagonal inward", "diagonal down right");
    side.mappings.set("diagonal outward", "diagonal down left");
  }
  if (side.direction === "bottom") {
    side.mappings.set("forward", "right");
    side.mappings.set("inward", "up");
    side.mappings.set("outward", "down");
    side.mappings.set("diagonal inward", "diagonal up right");
    side.mappings.set("diagonal outward", "diagonal down right");
  }
  if (side.direction === "right") {
    side.mappings.set("forward", "up");
    side.mappings.set("inward", "left");
    side.mappings.set("outward", "right");
    side.mappings.set("diagonal inward", "diagonal up left");
    side.mappings.set("diagonal outward", "diagonal up right");
  }
  if (side.direction === "top") {
    side.mappings.set("forward", "left");
    side.mappings.set("inward", "down");
    side.mappings.set("outward", "up");
    side.mappings.set("diagonal inward", "diagonal down left");
    side.mappings.set("diagonal outward", "diagonal up left");
  }
}

function makeNewSide(inDirection: SideCategory): Side {
  let newSide: Side = {
    direction: inDirection,
    mappings: new Map<RelativeDirection, AbsoluteDirection>(),
    cells: new Array<SideCell>(),
    endedOnDiagonalBumpy: false,
    endedOnStraightBumpy: false,
  };

  SetupAbsoluteDirections(newSide);
  return newSide;
}

function addNewCell(inSide: Side, inPosition: Coordinate): SideCell {
  let length = inSide.cells.push({
    position: { ...inPosition },
    direction: "up",
  });
  return inSide.cells[length - 1];
}

export class HouseGenerator {
  constructor(private baseOptions: GenerationOptions) {}

  public generate(): HouseData {
    const wallsBase = this.makeEmptyGrid();

    const options = {
      ...this.baseOptions,
    };

    const initialRectangle = options.initialRectangle;
    const maxOutward = options.maxOutward;
    let pos = { ...initialRectangle.start };

    let leftSide = makeNewSide("left");
    let currentDirection: RelativeDirection = "forward";

    this.GenerateSide(
      (position) => initialRectangle.end.y - position.y,
      (position) => initialRectangle.start.x - position.x,
      maxOutward,
      pos,
      options,
      currentDirection,
      leftSide,
      false,
      false,
      false
    );

    let bottomSide = makeNewSide("bottom");

    this.GenerateSide(
      (position) => initialRectangle.end.x - position.x,
      (position) => position.y - initialRectangle.end.y,
      maxOutward,
      pos,
      options,
      currentDirection,
      bottomSide,
      leftSide.endedOnDiagonalBumpy,
      leftSide.endedOnStraightBumpy,
      false
    );

    let rightSide = makeNewSide("right");

    this.GenerateSide(
      (position) => position.y - initialRectangle.start.y,
      (position) => position.x - initialRectangle.end.x,
      maxOutward,
      pos,
      options,
      currentDirection,
      rightSide,
      bottomSide.endedOnDiagonalBumpy,
      bottomSide.endedOnStraightBumpy,
      false
    );

    let topSide = makeNewSide("top");

    this.GenerateSide(
      (position) => position.x - initialRectangle.start.x,
      (position) => initialRectangle.start.y - position.y,
      maxOutward,
      pos,
      options,
      currentDirection,
      topSide,
      rightSide.endedOnDiagonalBumpy,
      rightSide.endedOnStraightBumpy,
      true
    );

    this.ConvertToWalls(wallsBase, leftSide);
    this.ConvertToWalls(wallsBase, bottomSide);
    this.ConvertToWalls(wallsBase, rightSide);
    this.ConvertToWalls(wallsBase, topSide);

    return { walls: wallsBase };
  }

  private GenerateSide(
    evaluateDistanceToDone: (position: Coordinate) => number,
    evaluateDistanceOutward: (position: Coordinate) => number,
    MaxOutward: number,
    startPos: Coordinate,
    options: GenerationOptions,
    currentDirection: RelativeDirection,
    side: Side,
    forceStartOnDiagonalBumpy: boolean,
    forceStartOnStraightBumpy: boolean,
    closeLoop: boolean
  ) {
    let pos = startPos;
    let OnDiagonalBumpy = false;

    while (evaluateDistanceToDone(pos) > 0) {
      let currentCell = addNewCell(side, pos);

      let disallowedDirections = new Array<RelativeDirection>();
      let distanceOutward = evaluateDistanceOutward(pos);
      let distanceToDone = evaluateDistanceToDone(pos);

      let forceInwards = closeLoop && distanceOutward >= distanceToDone;
      let noMoreOutwards = closeLoop && distanceOutward + 1 >= distanceToDone;

      let atMaxOutwards = distanceOutward >= MaxOutward;
      let atMaxInwards = distanceOutward <= 0;

      if (forceStartOnDiagonalBumpy) {
        OnDiagonalBumpy = true;
        disallowedDirections.push("forward");
        forceStartOnDiagonalBumpy = false;
      }

      if (OnDiagonalBumpy) {
        disallowedDirections.push("outward");
        disallowedDirections.push("inward");
      } else if (!atMaxInwards) {
        disallowedDirections.push("diagonal inward");
        disallowedDirections.push("diagonal outward");
      }

      if (forceInwards) {
        disallowedDirections.push("forward");
      }

      if (atMaxOutwards || noMoreOutwards) {
        disallowedDirections.push("outward");
        disallowedDirections.push("diagonal outward");
      }
      if (atMaxInwards) {
        disallowedDirections.push("inward");
        disallowedDirections.push("diagonal inward");
      }

      switch (currentDirection) {
        case "outward":
          {
            disallowedDirections.push("inward");
            disallowedDirections.push("diagonal inward");
          }
          break;
        case "inward":
          {
            disallowedDirections.push("outward");
            disallowedDirections.push("diagonal outward");
          }
          break;
        case "diagonal outward":
          {
            disallowedDirections.push("inward");
          }
          break;
        case "diagonal inward":
          {
            disallowedDirections.push("outward");
          }
          break;
      }

      if (disallowedDirections.includes(currentDirection)) {
        disallowedDirections.push("keep going");
      }

      let relativeDirection = this.GenerateRandomDirection(
        options,
        disallowedDirections
      );

      if (relativeDirection === "keep going") {
        relativeDirection = currentDirection;
      }

      if (atMaxInwards && relativeDirection === "diagonal outward") {
        OnDiagonalBumpy = true;
      }

      currentDirection = relativeDirection;

      let absoluteDirection = side.mappings.get(relativeDirection);
      if (absoluteDirection != undefined) {
        currentCell.direction = absoluteDirection;
      }

      this.MakeMovement(currentCell.direction, pos);

      if (evaluateDistanceOutward(pos) <= 0) {
        OnDiagonalBumpy = false;
      }
    }

    side.endedOnDiagonalBumpy = OnDiagonalBumpy;
    side.endedOnStraightBumpy =
      !OnDiagonalBumpy && evaluateDistanceOutward(pos) > 0;
  }

  private ConvertToWalls(walls: HouseGridCell[][], side: Side) {
    side.cells.forEach((cell) => {
      if (cell.direction === "up") {
        walls[cell.position.y - 1][cell.position.x].left = true;
      }
      if (cell.direction === "down") {
        walls[cell.position.y][cell.position.x].left = true;
      }
      if (cell.direction === "left") {
        walls[cell.position.y][cell.position.x - 1].top = true;
      }
      if (cell.direction === "diagonal down left") {
        walls[cell.position.y][cell.position.x - 1].topRightToBottomLeft = true;
      }
      if (cell.direction === "diagonal up left") {
        walls[cell.position.y - 1][cell.position.x - 1].topLeftToBottomRight =
          true;
      }
      if (cell.direction === "right") {
        walls[cell.position.y][cell.position.x].top = true;
      }
      if (cell.direction === "diagonal down right") {
        walls[cell.position.y][cell.position.x].topLeftToBottomRight = true;
      }
      if (cell.direction === "diagonal up right") {
        walls[cell.position.y - 1][cell.position.x].topRightToBottomLeft = true;
      }
    });
  }

  private MakeMovement(absoluteDirection: string, inPosition: Coordinate) {
    if (absoluteDirection === "up") {
      inPosition.y--;
    }
    if (absoluteDirection === "left") {
      inPosition.x--;
    }
    if (absoluteDirection === "down") {
      inPosition.y++;
    }
    if (absoluteDirection === "right") {
      inPosition.x++;
    }
    if (absoluteDirection === "diagonal up left") {
      inPosition.y--;
      inPosition.x--;
    }
    if (absoluteDirection === "diagonal down left") {
      inPosition.y++;
      inPosition.x--;
    }
    if (absoluteDirection === "diagonal down right") {
      inPosition.y++;
      inPosition.x++;
    }
    if (absoluteDirection === "diagonal up right") {
      inPosition.y--;
      inPosition.x++;
    }
  }

  private makeEmptyGrid() {
    const wallsBase: Array<Array<HouseGridCell>> = [];

    for (let y = 0; y < this.baseOptions.grid.height; y++) {
      wallsBase[y] = new Array<HouseGridCell>();
      for (let x = 0; x < this.baseOptions.grid.width; x++) {
        wallsBase[y][x] = makeEmptyCell();
      }
    }

    return wallsBase;
  }

  private GenerateRandomDirection(
    options: GenerationOptions,
    disallowedDirections: Array<RelativeDirection>
  ): RelativeDirection {
    const weights = new Map(options.weights);

    disallowedDirections.forEach((direction) => weights.delete(direction));

    let maxWeight = 0;
    weights.forEach((weight) => (maxWeight += weight));

    let randomNum = options.randomGenerator() * maxWeight;

    let currentWeight = 0;
    let directionToReturn: RelativeDirection = "forward";
    let foundWeight = false;

    weights.forEach((weight, direction) => {
      if (!foundWeight) {
        currentWeight += weight;
        if (randomNum <= currentWeight) {
          directionToReturn = direction;
          foundWeight = true;
        }
      }
    });

    return directionToReturn;
  }

  private GenerateRandomPosition(
    minPos: number,
    maxPos: number,
    options: GenerationOptions
  ) {
    return minPos + Math.floor(options.randomGenerator() * (maxPos - minPos));
  }
}
