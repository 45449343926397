import React, { useEffect, useRef } from "react";

import { GenerationOptions } from "../../renderer/renderer";

export type DrawFunction = (
  ctx: CanvasRenderingContext2D,
  frameCount: number,
  options: GenerationOptions
) => void;
export function Canvas({
  drawFunction,
  options,
  canvasProps,
}: {
  drawFunction: DrawFunction;
  options: GenerationOptions;
  canvasProps?: React.DetailedHTMLProps<
    React.CanvasHTMLAttributes<HTMLCanvasElement>,
    HTMLCanvasElement
  >;
}) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    let frameCount = 0;
    let animationFrameId: number | null = null;

    // Our draw came here
    const render = () => {
      frameCount++;
      if (context) {
        drawFunction(context, frameCount, options);
      }
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      if (animationFrameId) {
        window.cancelAnimationFrame(animationFrameId);
      }
    };
  }, [drawFunction, options]);

  return (
    <canvas
      className="border"
      style={{ width: "100%" }}
      ref={canvasRef}
      width={options.canvas.width}
      height={options.canvas.height}
      {...canvasProps}
    />
  );
}
